import {defineNuxtRouteMiddleware, extractQueryParameters, guestHost, useRuntimeConfig, useUniversalFetch} from '#imports';
import {navigateTo} from '@typed-router';
import type {RouteLocationNormalized} from 'vue-router';

type SeoUrlResource = {
    url: string;
    urlWithoutInvariant: string;
};

export default defineNuxtRouteMiddleware(async (to: RouteLocationNormalized) => {
    const config = useRuntimeConfig();
    const host = guestHost();
    try {
        const queryParams = extractQueryParameters(to);
        // will throw a 403 error when unlogged (yep, 403...)
        const seoUrl = await useUniversalFetch<SeoUrlResource>('/api/url');
        return navigateTo(`${host}${seoUrl.url}?${queryParams}`, {external: true});
    } catch (error) {
        const redirectParam = config.app.baseURL + to.fullPath + to.hash;
        return navigateTo(`${host}/signin?redirect=${encodeURIComponent(redirectParam)}`, {external: true});
    }
});
